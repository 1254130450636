// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className='container-fluid footer'>
    <div className='row'>
      <div className='col-md-3'>
      <h3 class="footer-title">About us</h3>
      <p class="aboutus-para">
      We are India's 2nd largest home interior designer store, located in Chennai, offering expert services to transform your living space. Whether you’re redesigning your home or creating a brand-new look, our team is here to craft unique and functional designs tailored just for you.</p>
      </div>
      <div className='col-md-3'>
      <h3 class="footer-title">Quick Links</h3>
          <ul>
            <li class="footer-link"><Link to="/"><i class="fa fa-arrow-right" aria-hidden="true"></i>Home</Link></li>
           <li class="footer-link"> <Link to="/interior"><i class="fa fa-arrow-right" aria-hidden="true"></i>Interior</Link></li>
            <li class="footer-link"><Link to="/renovation"><i class="fa fa-arrow-right" aria-hidden="true"></i>Renovation</Link></li>
            <li class="footer-link"><Link to="/gallery"><i class="fa fa-arrow-right" aria-hidden="true"></i>View Our Sites</Link></li>
            <li class="footer-link"><Link to="/privacy-policy"><i class="fa fa-arrow-right" aria-hidden="true"></i>Privacy Policy</Link></li>
          </ul>
      </div>
      <div className='col-md-3'>
      <h3 class="footer-title">Services</h3>
          <ul>
          <li class="footer-link"><Link to="/kitchen"><i class="fa fa-arrow-right" aria-hidden="true"></i>Modular Kitchen</Link></li>
            <li class="footer-link"><Link to="/wardrobe"><i class="fa fa-arrow-right" aria-hidden="true"></i>Wardrobe Designs</Link></li>
            <li class="footer-link"><Link to="/kids-furniture"><i class="fa fa-arrow-right" aria-hidden="true"></i>Kids Furniture</Link></li>
            <li class="footer-link"><Link to="/tvunit"><i class="fa fa-arrow-right" aria-hidden="true"></i>TV Units</Link></li>
            <li class="footer-link"><Link to="/poojaunit"><i class="fa fa-arrow-right" aria-hidden="true"></i>Pooja Units</Link></li>
          </ul>
      </div>
      <div className='col-md-3'>
      <h3 class="footer-title">Contact us</h3>
          <ul>
            <li class="footer-link"><i class="fa fa-phone" aria-hidden="true"></i>
              +91-9150005679</li>
            <li class="footer-link"><i class="fa fa-envelope" aria-hidden="true"></i>info@cookscape.com</li>
            <li class="footer-link"><i class="fa fa-map-marker" aria-hidden="true"></i>
              557/338, Anna Salai, Fanepet, Nandanam, Chennai, Tamil Nadu 600035.</li>
          </ul>
  
      </div>
      </div>
      <div class="copyright">
      <p class="copyright-para">By clicking this page, you agree to our Terms & Conditions and Privacy Policy | © Copyright 2024 All Rights Reserved © Cookscape Private Limited.</p>
    </div>
    </div>
  );
};
export default Footer;
